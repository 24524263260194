import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import axios from 'axios'
import './registerServiceWorker'
import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import Notifications from 'vue-notification'
import validationMessagesRO from './locale/ro'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueSignaturePad from 'vue-signature-pad'
import { syncForOffline, checkCookie } from './sync'
import VueLodash from 'vue-lodash'
import localforage from 'localforage'

localforage.config({
  driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: 'prime-kapital',
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'prime_kapital', // Should be alphanumeric, with underscores.
  description: 'Prime Kapital App'
})

Vue.use(VueSignaturePad)
const lodashOptions = { name: 'lodash' }
Vue.use(VueLodash, lodashOptions)
Vue.use(BootstrapVue)
Vue.use(VeeValidate, {
  events: 'change|blur',
  locale: 'ro',
  dictionary: {
    ro: validationMessagesRO
  }
})
Vue.use(Notifications)
// Validator.localize('ro', validationMessagesRO)

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
}

Vue.use(VueSweetalert2, options)

Vue.config.productionTip = false

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
axios.defaults.headers.common['X-localization'] = 'ro'
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

axios.interceptors.response.use(function (response) {
    return response;
    
    }, function (error) {
        
    if (error.response.status === 403) {
        window.location.href = "/";
    }
    
    return Promise.reject(error);
  });

const token = localStorage.getItem('user-token') || null
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

if (!checkCookie('synced')) {
  syncForOffline()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
