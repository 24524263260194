import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import LoginService from '../api-services/Login'
import CampaignService from '../api-services/Campaign'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    token: localStorage.getItem('user-token') || null,
    user: (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null,
    selectedCampaign: (localStorage.getItem('selectedCampaign')) ? JSON.parse(localStorage.getItem('selectedCampaign')) : null,
    status: null
  },
  getters: {
    isAuthenticated: state => !!state.token,
    selectedCampaign: state => state.selectedCampaign,
    user: state => state.user,
    authStatus: state => state.status
  },
  mutations: {
    CHANGE_LOADING (state, payload) {
      state.loading = payload || false
    },
    AUTH_REQUEST (state) {
      state.status = 'loading'
    },
    AUTH_SUCCESS (state, token) {
      state.status = 'success'
      state.token = token
    },
    AUTH_SET_USER (state, user) {
      state.user = user
    },
    AUTH_ERROR (state) {
      state.status = 'error'
    },
    AUTH_LOGOUT (state) {
      state.status = null
      state.token = null
      state.user = null
      state.selectedCampaign = null
    },
    CHANGE_CAMPAIGN (state, campaign) {
      state.selectedCampaign = campaign || null
    }
  },
  actions: {
    showLoading ({ dispatch, commit }) {
      commit('CHANGE_LOADING', true)
    },
    hideLoading (state) {
      state.commit('CHANGE_LOADING', false)
    },
    authRequest ({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST')
        LoginService.login(user)
          .then((response) => {
            const token = response.data.data.token
            localStorage.removeItem('user') // clear your user's token from localstorage
            // localStorage.removeItem('user-token') // clear your user's token from localstorage
            localStorage.removeItem('selectedCampaign') // clear your user's token from localstorage
            localStorage.setItem('user-token', token) // store the token in localstorage
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            commit('AUTH_SUCCESS', token)
            // you have your token, now log in your user :)
            // dispatch('userRequest')
            resolve(dispatch('userRequest'))
          })
          .catch((error) => {
            commit('AUTH_ERROR', error)
            localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
            reject(error)
          })
      })
    },
    userRequest ({ commit, dispatch }) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        LoginService.getAuthUser()
          .then((response) => {
            const user = response.data.data
            localStorage.setItem('user', JSON.stringify(user)) // store the token in localstorage
            commit('AUTH_SET_USER', user)
            resolve(response)
          })
          .catch((error) => {
            dispatch('logoutRequest')
            reject(error)
          })
      })
    },
    logoutRequest ({ commit, dispatch }) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_LOGOUT')
        LoginService.logout()
          .then((response) => {
            localStorage.removeItem('user-token') // clear your user's token from localstorage
            localStorage.removeItem('user') // clear your user's token from localstorage
            localStorage.removeItem('selectedCampaign') // clear your user's token from localstorage
            delete axios.defaults.headers.common['Authorization']
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    logoutRequestLocal ({ commit, dispatch }) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_LOGOUT')
        localStorage.removeItem('user-token') // clear your user's token from localstorage
        localStorage.removeItem('user') // clear your user's token from localstorage
        localStorage.removeItem('selectedCampaign') // clear your user's token from localstorage
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    changeCampaign ({ commit, dispatch }, campaignId) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        dispatch('showLoading')
        CampaignService.get(campaignId)
          .then((response) => {
            let campaign = response.data.data
            localStorage.setItem('selectedCampaign', JSON.stringify(campaign))
            commit('CHANGE_CAMPAIGN', campaign)
            resolve(campaign)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    syncSelectedCampaign ({ commit, dispatch }, campaign) {
      return new Promise((resolve, reject) => {
        console.log('syncSelectedCampaign')
        dispatch('showLoading')
        localStorage.setItem('selectedCampaign', JSON.stringify(campaign))
        commit('CHANGE_CAMPAIGN', campaign)
        resolve(campaign)
      })
    }
  }
})
