<template>
    <div id="wrapper">
        <Header/>
        <main>
            <Loading v-if="this.$store.state.loading"/>
            <router-view/>
        </main>
    </div>
</template>

<script>
import Header from './partials/Header'
import Loading from '../components/Loading'

export default {
  name: 'Template',
  components: {
    Header,
    Loading
  },
  updated () {

  },
  mounted () {

  },
  methods: {
  }
}
</script>
