import axios from 'axios'
import store from '../store/store'
import localforage from 'localforage'
const RESOURCE_NAME = '/campaigns'

export default {
  async isOnline () {
    // return true
    let output = false
    await axios.get(`/test`)
      .then((response) => {
        output = true
      })
      .catch((error) => {
        output = false
      })
    return output
  },
  async getActiveCampaigns () {
    if (await this.isOnline()) {
      return axios.get(`${RESOURCE_NAME}`)
    } else {
      return this.getActiveCampaignsOffline()
    }
  },

  async getPrizesForCampaign (campaignId) {
    if (await this.isOnline()) {
      return axios.get(`${RESOURCE_NAME}/${campaignId}/get-prizes`)
    } else {
      return this.getPrizesForCampaignOffline()
    }
  },

  async getShopsForCampaign (campaignId) {
    if (await this.isOnline()) {
      return axios.get(`${RESOURCE_NAME}/${campaignId}/get-shops`)
    } else {
      return this.getShopsForCampaignOffline()
    }
  },

  async get (id) {
    if (await this.isOnline()) {
      return axios.get(`${RESOURCE_NAME}/${id}`)
    } else {
      console.log('offline')
      return this.getOffline()
    }
  },

  getPrizesForCampaignOffline () {
    return new Promise((resolve, reject) => {
      let results = {
        data: {
          data: []
        }
      }
      localforage.getItem('prizes').then((response) => {
        let prizes = store.getters.selectedCampaign.campaignStocks
        prizes.forEach(function (element, index) {
          element['id'] = element.prize_id
          element['name'] = response.find(prize => prize.id === element.prize_id).name
          element['media'] = response.find(prize => prize.id === element.prize_id).media
        })
        // console.log(prizes)
        results.data.data = prizes
        resolve(results)
      })
    })
  },

  getShopsForCampaignOffline () {
    return new Promise((resolve, reject) => {
      let results = {
        data: {
          data: []
        }
      }
      results.data.data = store.getters.selectedCampaign.shops
      resolve(results)
    })
  },

  getOffline () {
    return new Promise((resolve, reject) => {
      let results = {
        data: {
          data: []
        }
      }
      results.data.data = store.getters.selectedCampaign
      resolve(results)
    })
  },

  getActiveCampaignsOffline () {
    return new Promise((resolve, reject) => {
      let results = {
        data: {
          data: []
        }
      }
      localforage.getItem('activeCampaigns').then((response) => {
        results.data.data = response
        resolve(results)
      })
    })
  }
}
