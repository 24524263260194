<template>
    <header>
        <section class="header">
            <!-- <div class="header__company"> -->
                <router-link class="header__company-name" :to="{ path: '/' }">
                  <!-- <img src="./../../assets/images/Logo_PK3.png" alt="prime_kapital" class="header__img"> -->
                  {{this.appName}}
                </router-link>
            <!-- </div> -->
            <div class="header__menu">
                <button class="btn header__btn" @click="logout()">
                    Deconectare
                    <i class="fas fa-sign-out-alt header__icon"></i>
                </button>
            </div>
        </section>
    </header>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('showLoading')
      this.$store.dispatch('logoutRequest').then((response) => {
        this.$store.dispatch('hideLoading')
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>
