import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'
import Landing from './views/campaign/Landing'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "about" */ './views/Login'),
      meta: {
        requiresAuth: false,
        requiresCampaign: false
      }
    },
    {
      path: '/',
      name: 'landing-page',
      component: Landing,
      meta: {
        requiresAuth: true,
        requiresCampaign: true
      }
    },
    {
      path: '/select-campaign',
      name: 'select-campaign',
      component: () => import(/* webpackChunkName: "about" */ './views/campaign/SelectCampaign'),
      meta: {
        requiresAuth: true,
        requiresCampaign: false
      }
    },
    {
      path: '/add-user',
      name: 'add-user',
      component: () => import(/* webpackChunkName: "about" */ './views/clients/AddParticipant'),
      meta: {
        requiresAuth: true,
        requiresCampaign: true
      }
    },
    {
      path: '/prizes',
      name: 'prizes',
      component: () => import(/* webpackChunkName: "about" */ './views/prizes/Prizes'),
      meta: {
        requiresAuth: true,
        requiresCampaign: true
      }
    }
  ]
})

router.afterEach((to, from) => {
  store.dispatch('showLoading')
})

router.beforeEach((to, from, next) => {
  // check auth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      if (to.matched.some(record => record.meta.requiresCampaign)) {
        if (store.getters.selectedCampaign) {
          next()
          return
        }
        next('/select-campaign')
      }
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
