import axios from 'axios'
import localforage from 'localforage'
import store from '../store/store'

const RESOURCE_NAME = '/clients'

export default {
  async isOnline () {
    // return true
    let output = false
    await axios.get(`/test`)
      .then((response) => {
        output = true
      })
      .catch((error) => {
        output = false
      })
    return output
  },
  getAll () {
    return axios.get(`${RESOURCE_NAME}`)
  },
  async addParticipant (data) {
    if (await this.isOnline()) {
      console.log('Add Online')
      return axios.post(`/add-participant`, data)
    } else {
      console.log('Add Offline')
      return this.addParticipantOffline(data)
    }
  },

  async checkParticipant (phone, campaignId) {
    if (await this.isOnline()) {
      // return this.checkParticipantOffline(phone, campaignId)
      return axios.get(`/autocomplete/${phone}/${campaignId}`)
    } else {
      console.log(1);
      return this.checkParticipantOffline(phone, campaignId)
    }
  },
  addParticipantOffline (data) {
    return new Promise((resolve, reject) => {
      let uniqueReceipt = []
      let totalAmount = 0
      let jsonData = Object.fromEntries(data)
      let receipts = JSON.parse(jsonData.receiptsForOffline)
      receipts.forEach(function (element, index) {
        let temp = element.shop + '-' + element.nr + '-' + element.amount
        if (uniqueReceipt.indexOf(temp) !== -1) {
          let err = {
            error: 'Un bon de cumparaturi este adaugat de doua ori.<br> Va rugam sa verificati bonurile adaugate',
            status: 422
          }
          reject(err)
          return false
        }
        uniqueReceipt.push(temp)
        totalAmount += parseFloat(element.amount)
      })
      if (parseFloat(totalAmount) < parseFloat(store.getters.selectedCampaign.min_amount)) {
        let err = {
          error: `Valoarea minima a bonurilor pentru a putea participa la aceasta campanie este ${store.getters.selectedCampaign.min_amount}RON`,
          status: 422
        }
        reject(err)
        return false
      }
      axios.post(`/add-participant`, data)
      resolve(true)
    })
  },
  checkParticipantOffline (phone, campaignId) {
    return new Promise((resolve, reject) => {
      let results = {
        data: {
          data: null
        }
      }
      localforage.getItem('clients').then((response) => {
        // console.log(phone)
        let client = response.find(client => client.phone === phone)
        if (client) {
          let participationNr = 0
          let participationNrToday = 0
          let participants = store.getters.selectedCampaign.campaignParticipants.filter(function (data) {
            if (data.campaign_id === campaignId && data.client_phone === client.phone) {
              return data
            }
          })
          if (participants.length > 0) {
            participationNr = participants.length
          }
          let currentDatetime = new Date()
          let formattedDate = currentDatetime.getFullYear() + '-' + (currentDatetime.getMonth() + 1) + '-' + (currentDatetime.getDate() < 10 ? '0' : '') + currentDatetime.getDate()
          let participantsToday = participants.filter(function (data) {
            if (data.date == formattedDate) {
              return data
            }
          })
          if (participantsToday.length > 0) {
            participationNrToday = participantsToday.length
          }
          client['participation_nr'] = participationNr
          client['participation_nr_today'] = participationNrToday
          results.data.data = client
          resolve(results)
        } else {
          resolve(results)
        }
      })
    })
  }
}
