import axios from 'axios'

export default {
  login (data) {
    return axios.post('/login', data)
  },
  logout () {
    return axios.post('/logout')
  },
  getAuthUser () {
    return axios.get('/user')
  }
}
