import localforage from 'localforage'
import ClientsService from './api-services/Client'
import CampaignsService from './api-services/Campaign'
import GlobalService from './api-services/Global'
import PrizesService from './api-services/Prize'

import store from './store/store'
// import ContactsService from './api-services/contacts.service'
// import LocationsService from './api-services/locations.service'
// import Nivel1Service from './api-services/nivel1.service'
// import Nivel2Service from './api-services/nivel2.service'
// import Nivel3Service from './api-services/nivel3.service'

export function syncClients () {
  ClientsService.getAll()
    .then((response) => {
      localforage.setItem('clients', response.data.data)
    })
}

export function syncPrizes () {
  PrizesService.getAll()
    .then((response) => {
      localforage.setItem('prizes', response.data.data)
    })
}

export function syncShops () {
  PrizesService.getAll()
    .then((response) => {
      localforage.setItem('prizes', response.data.data)
    })
}

export function syncAllCampaigns () {
  CampaignsService.getActiveCampaigns()
    .then((response) => {
      localforage.setItem('activeCampaigns', response.data.data)
    })
}

export function syncAllLocations () {
  GlobalService.getLocations()
    .then((response) => {
      localforage.setItem('locations', response.data.data)
    })
}

export function syncSettings () {
  GlobalService.getSettings()
    .then((response) => {
      localforage.setItem('settings', response.data.data)
    })
}

function createCookie (cookieName, cookieValue, daysToExpire) {
  let date = new Date()
  date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000))
  document.cookie = cookieName + '=' + cookieValue + '; expires=' + date.toGMTString()
}

export function checkCookie (cookieName) {
  let name = cookieName + '='
  let allCookieArray = document.cookie.split(';')
  for (let i = 0; i < allCookieArray.length; i++) {
    let temp = allCookieArray[i].trim()
    if (temp.indexOf(name) === 0) {
      return true
    }
  }
  return false
}

export function syncForOffline () {
  if (store.getters.isAuthenticated) {
    syncClients()
    syncAllCampaigns()
    syncPrizes()
    syncShops()
    syncSettings()
    syncAllLocations()
    createCookie('synced', 1, 1)
  }
}
