export default {
  name: 'ro',
  messages: {
    after: function (e, n) {
      return 'Campul ' + e + ' trebuie sa fie dupa ' + n[0] + '.'
    },
    alpha: function (e) {
      return 'Campul ' + e + ' poate contine doar literele alfabetului.'
    },
    alpha_dash: function (e) {
      return 'Campul ' + e + ' poate contine litere si caracterele "-" sau "_".'
    },
    alpha_num: function (e) {
      return 'Campul ' + e + ' poate contine doar caractere alfanumerice.'
    },
    alpha_spaces: function (e) {
      return 'Campul ' + e + ' poate contine literele si spatii.'
    },
    before: function (e, n) {
      return 'Campul ' + e + ' trebuie sa fie dupa ' + n[0] + '.'
    },
    between: function (e, n) {
      return 'Valoare campului ' + e + ' trebuie sa fie între ' + n[0] + ' si ' + n[1] + '.'
    },
    confirmed: function (e) {
      return 'Campul ' + e + ' nu coincide.'
    },
    credit_card: function (e) {
      return 'Valoarea campului ' + e + ' nu este un numar de card valid.'
    },
    date_between: function (e, n) {
      return 'Data introdusa în ' + e + ' trebuie sa fie între ' + n[0] + ' si ' + n[1] + '.'
    },
    date_format: function (e, n) {
      return 'Respectati urmatorul format: ' + n[0] + ' în campul ' + e + '.'
    },
    digits: function (e, n) {
      return 'Campul ' + e + ' trebuie sa fie numeric si sa contina exact ' + n[0] + ' caractere.'
    },
    dimensions: function (e, n) {
      return 'Campul ' + e + ' trebuie sa fie ' + n[0] + ' pixeli lungime si ' + n[1] + ' pixeli înaltime.'
    },
    email: function (e) {
      return 'Campul ' + e + ' trebuie sa contina un email valid.'
    },
    excluded: function (e) {
      return 'Campul ' + e + ' trebuie sa contina o valoare valida.'
    },
    ext: function (e) {
      return 'Campul ' + e + ' trebuie sa fie un nume de fisier valid.'
    },
    image: function (e) {
      return 'Campul ' + e + ' trebuie sa fie o imagine.'
    },
    included: function (e) {
      return 'Campul ' + e + ' trebuie sa contina o valoare valida.'
    },
    ip: function (e) {
      return 'Campul ' + e + ' trebuie sa contina o adresa IP valida.'
    },
    max: function (e, n) {
      return 'Campul ' + e + ' nu poate contine mai mult de ' + n[0] + ' caractere.'
    },
    max_value: function (e, n) {
      return 'Valoarea campului ' + e + ' trebuie sa fie maxim ' + n[0] + '.'
    },
    mimes: function (e) {
      return 'Campul ' + e + ' trebuie sa contina un fisier cu extensie valida.'
    },
    min: function (e, n) {
      return 'Campul ' + e + ' trebuie sa contina cel putin ' + n[0] + ' caractere.'
    },
    min_value: function (e, n) {
      return 'Valoarea campului ' + e + ' trebuie sa fie mai mare de ' + n[0] + '.'
    },
    numeric: function (e) {
      return 'Campul ' + e + ' poate contine doar valori numerice.'
    },
    regex: function (e) {
      return 'Formatul campului ' + e + ' este invalid.'
    },
    required: function (e) {
      return 'Campul ' + e + ' este obligatoriu.'
    },
    size: function (e, n) {
      return 'Campul ' + e + ' nu trebuie sa depaseasca ' + function (e) {
        var n = 1024,
          i = 0 === (e = Number(e) * n) ? 0 : Math.floor(Math.log(e) / Math.log(n))
        return 1 * (e / Math.pow(n, i)).toFixed(2) + ' ' + ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
      }(n[0]) + '.'
    },
    url: function (e) {
      return 'Campul ' + e + ' nu este o adresa URL valida.'
    }
  },
  attributes: {}
}
