<template>
  <div id="app">
    <Template v-if='this.$route.name !== "login"'/>
    <router-view v-else />
    <notifications group="succes" position="bottom right" />
    <notifications group="error" position="top right"  />
  </div>
</template>

<script>
import Template from './template/Template'
import axios from 'axios'
export default {
  name: 'app',
  components: {
    Template
  },
  created () {
    let vm = this
    axios.interceptors.response.use(undefined, (err) => {
      let error = err.response
      if (error && error.status === 401 && error.config && !error.config.__isRetryRequest) {
        vm.$store.dispatch('logoutRequestLocal').then((response) => {
          vm.$router.push({ name: 'login' })
        })
      }
      if (error && error.status === 403 && error.config && !error.config.__isRetryRequest) {
        vm.$router.push('/')
      }
      throw err
    })
  }
}
</script>
<style lang="scss">
  @import "assets/css/app";
</style>
