import axios from 'axios'
import localforage from 'localforage'

export default {
  async isOnline () {
    // return true
    let output = false
    await axios.get(`/test`)
      .then((response) => {
        output = true
      })
      .catch((error) => {
        output = false
      })
    return output
  },
  async getLocations () {
    if (await this.isOnline()) {
      return axios.get(`/get-locations`)
    } else {
      return this.getLocationsOffline()
    }
  },

  async getSettings () {
    if (await this.isOnline()) {
      return axios.get(`/get-settings`)
    } else {
      return this.getSettingsOffline()
    }
  },

  getSettingsOffline () {
    return new Promise((resolve, reject) => {
      let results = {
        data: {
          data: []
        }
      }
      localforage.getItem('settings').then((response) => {
        results.data.data['gdpr_info'] = response.gdpr_info
        resolve(results)
      })
    })
  },

  getLocationsOffline () {
    return new Promise((resolve, reject) => {
      let results = {
        data: {
          data: []
        }
      }
      localforage.getItem('locations').then((response) => {
        results.data.data = response
        resolve(results)
      })
    })
  }
}
