<template>
    <b-carousel
      id="carousel-1"
      :interval="10000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
    >
        <b-carousel-slide v-for="banner in media" :key="banner.id"
            :style="{ backgroundImage: `url(${banner.link})` }"
        ></b-carousel-slide>
    </b-carousel>
</template>

<script>
import CampaignService from '../api-services/Campaign'
export default {
  name: 'Banner',
  data () {
    return {
      media: null
    }
  },
  methods: {
    getCampaignMedia () {
      return new Promise((resolve, reject) => {
        this.campaign = null
        CampaignService.get(this.$store.getters.selectedCampaign.id)
          .then((response) => {
            this.media = response.data.data.media
            resolve(response.data.data.media)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  async created () {
    await this.getCampaignMedia()
  }
}
</script>

<style>

</style>
